<template>
    <div class="accordion">
        <div class="accordion-header flex justify-between items-center" @click="toggle()"><span>{{ header }}</span><i class="fas fa-chevron-down" :class="{ active: active }"></i></div>
        <div :id="`accordion-content-${id}`" class="accordion-content" v-html="content" :class="{ active: active }"></div>
    </div>
</template>

<script>
export default {
    name: 'Accordion',
    props: [
        'header',
        'content',
        'id'
    ],
    data() {
        return {
            active: false
        }
    },
    methods: {
        toggle() {
            let e = document.getElementById(`accordion-content-${this.id}`);
            if (e.offsetHeight == 0) {
                e.style.maxHeight = e.scrollHeight + 'px';
            } else {
                e.style.maxHeight = '0px';
            }

            this.active = !this.active
        }
    }
}
</script>

<style scoped>

.accordion-header > i {
    @apply transition-all;
}

.accordion-header > i.active {
    @apply transform rotate-180;
}

.accordion-content {
    @apply transition-all max-h-0 overflow-hidden;
}

</style>