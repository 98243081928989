<template>
    <main class="main-container">
        <div class="wrapper">
            <div>
                <div class="flex justify-center items-center gap-4 mb-10">
                    <img src="@/assets/images/project_icons/pokenews.png" height="40" width="40" />
                    <h1 class="text-sky-100 font-bold text-2xl">Pokénews Changelog</h1>
                </div>

                <div v-if="load" class="flex justify-center items-center gap-2">
                    <span class="text-xl">Loading</span>
                    <i class="fas fa-circle-notch fa-spin text-xl"></i>
                </div>

                <div v-if="error" class="text-red-500 flex justify-center items-center font-bold text-xl">
                    Changelog Server nicht erreichbar
                </div>

                <ChangelogEntry v-for="changelogEntry in changelog" 
                    :key="changelogEntry.id" 
                    :id="changelogEntry.id" 
                    :version="changelogEntry.version" 
                    :date="changelogEntry.date" 
                    :description="changelogEntry.description" />
            </div>
        </div>
    </main>
</template>

<script>
import axios from 'axios';
import ChangelogEntry from '@/components/ChangelogEntry.vue';

export default {
    name: 'Changelog',
    components: {
        ChangelogEntry
    },
    data() {
        return {
            changelog: [],
            load: true,
            error: false
        }
    },
    async created() {

        if ('changelog' in sessionStorage) {

            this.changelog = JSON.parse(sessionStorage.getItem('changelog'));
            this.load = false;

        } else {

            await axios.get('https://api.diafischchen.net/pokenews/changelog')
                .then((response) => {
                    response.data.forEach(element => {
                        this.changelog.push(element);
                    });

                    sessionStorage.setItem('changelog', JSON.stringify(response.data))
                })
                .catch(() => {
                    this.error = true;
                })
                .then(() => {
                    this.load = false;
                })

        }


    }
}
</script>

<style scoped>

</style>