<template>
    <div class="mt-5 bg-gray-800 p-4 rounded-md shadow-md">
        <Accordion :header="`${formatDate(date)} - Version: ${version}`" :content="description" :id="id" />
    </div>
</template>

<script>
import Accordion from '@/components/Accordion.vue'

export default {
    name: 'ChangelogEntry',
    components: {
        Accordion
    },
    props: [
        'id',
        'version',
        'date',
        'description'
    ],
    methods: {
        formatDate(date) {
            let dateObj = new Date(date);
            return dateObj.toLocaleDateString("de-DE");
        }
    }
}
</script>

<style scoped>

::v-deep(.accordion-header) {
    @apply text-lg select-none cursor-pointer font-bold text-sky-100;
}

::v-deep(.accordion-content.active) {
    @apply mt-4;
}

::v-deep(.accordion-content a) {
    @apply text-sky-400 underline;
}

::v-deep(.accordion-content ul) {
    @apply mb-3 mt-2 list-disc;
}

::v-deep(.accordion-content li) {
    @apply ml-10;
}

</style>